
(() => {
	if (!$('body.pilots').length) return;

	const $map = $('body.pilots #map');
	const $balloons = $map.find('.location .balloon');

	$map.find('.pin').on('click', (e) => {
		const $balloon = $(e.currentTarget).find('+ .balloon');
		$balloons.not($balloon)
			.addClass('hide')
			.parents('.location').removeClass('active');

		$balloon.toggleClass('hide');
		$balloon.parents('.location:first').toggleClass('active');
	})

})();
