import '../scss/index.scss';

import Utils from './utils';
import Swiper from 'swiper';
import stickyfill from 'stickyfilljs';
import screenDetector from './screen-detector'; // initializes screensize etc.

const urlParams = Utils.getQueryStringParams(window.location.search);
const login = urlParams.login;

import './nav.js';
import './pilots-map.js';
//import './locations.js';
//import './browse-products.js';
//import './add-subscriber.js';
//import './product-detail.js';
//import './order-now.js';
//import './search-results.js';
import formValidator from './form-validator.js';
import modals from './modals.js';


$(document).ready(() => {
	stickyfill.add($('.sticky'));

	modals.initModals();

	if (typeof login === 'string') {
		showModal('login');
	}

	formValidator.processForms();

	// submit country bar on hitting close button (so it will hide after saving preference)
	$('.country-bar .trigger.t_close').click(function(){
		$(this).parents('.country-bar:first').find('form').submit();
	});

	// latest products swiper
	var mySwiper = new Swiper('section.latest-products .swiper-container', {

		slidesPerView: 3,
	    spaceBetween: 0,
	    watchOverflow: false,

		// Navigation arrows
	    navigation: {
	      nextEl: '.swiper-button-next',
	      prevEl: '.swiper-button-prev',
	    },
	    breakpoints: {
			// when window width is <= 768px
			767: {
				slidesPerView: 1
			},
			// when window width is <= 980px
			991: {
				slidesPerView: 2
			}
		}
	});
});
