//require("expose?$!jquery");

(() => {
	var $popups = $('#nav-main .popup');

	$('#nav-main .trigger-mobile.t_menu').click( e => {
		e.preventDefault();
		$('.top-bar').toggleClass('active');
	});

	$('#nav-main .mobile-menu .primary > li').click( (e) => {
		$target = $(e.target);
		$currentTarget = $(e.currentTarget);
		if (e.target.tagName.toLowerCase() !== 'li') return;
		$(e.currentTarget).toggleClass('active');
	});	

	$('#nav-main .trigger').click( e => {
		e.preventDefault();
		var $trigger = $(e.currentTarget);
		var $el = $trigger.parent();
		var $popup = $el.find('.popup');

		$popups.not($popup).addClass('hide-large-up');

		if ($trigger.attr('id') == 'toggle-search') {
			if ($(e.target).is('input')) return
			
			$popup.toggleClass('hide-large-up');
			if ($popup.is(':visible')) {
				$popup.find('input').focus();
			}				
		} else {
			$popup.toggleClass('hide-large-up');			
		}
	});
})();
