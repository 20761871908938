// variables.js

import variables from './variables';
const breakpoints = variables.breakpoints;

export const screenMatcher = () => {
	let width = $(window).width();
	if (width <= breakpoints.sm) return 'small';
	else if (width > breakpoints.sm && width <= breakpoints.md) return 'medium';
	else if (width > breakpoints.md && width <= breakpoints.lg) return 'large';
	else if (width > breakpoints.lg) return 'xlarge';
}

